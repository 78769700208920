import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
const sections = gsap.utils.toArray(".home-pages--section");

sections.map((section) => {
  const bgImage = section.querySelector(".bg-image");
  gsap
    .timeline({
      scrollTrigger: {
        trigger: section,
        start: "top 70%",
        end: "bottom 30%",
        scrub: true,
        // markers: true,
      },
      yoyo: true,
    })
    .to(bgImage, { scale: "1.3" })
    .to(bgImage, { scale: "1" }, "+=0.01");
});
